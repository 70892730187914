.filter-container {
  width: 20vw;

  margin-top: 25%;
}
.list_item {
  font-family: "League Spartan", sans-serif;
  font-size: 18px;
  margin: 0;
}
.sub_list_item {
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  margin: 0 20px;
}
.list_item_link {
  color: black; /* Custom text color */
  text-decoration: none; /* Remove underline */
  padding: 7px; /* Padding around the link */
  border: 1px solid transparent; /* Transparent border for hover effect */
  transition: all 0.3s ease; /* Smooth transition for hover effect */

  display: block;
}

.list_item_link:hover {
  color: #3598b6; /* Change color on hover */
  border-color: lightgray; /* Add border color on hover */
  text-decoration: none; /* Add underline on hover */
}
